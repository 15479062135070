<template>
  <div class="home">
    <img
      style="width: 10rem; height: 8.87rem"
      alt="Vue logo"
      src="../assets/bg.png"
    />
    <div class="form-content">
      <van-field
        class="cont"
        v-model="name"
        label="申请人"
        placeholder="请输入本人真实姓名"
        :disabled="nameDisabled"
        label-width="4.2em"
      />
      <van-field
        class="cont"
        v-model="phone"
        label="手机号"
        type="tel "
        placeholder="请输入手机号"
        label-width="4.2em"
      >
        <template #button>
          <div
            v-show="phoneisUpdate"
            style="
              color: #0d76ff;
              margin-right: 0px;
              width: 60px;
              text-align: left;
              padding: 0;
            "
            @click="showphonezw"
          >
            修改
          </div>
        </template>
      </van-field>
      <van-field
        class="cont"
        v-model="carnum"
        label="车牌号"
        placeholder="请输入车牌号"
        label-width="4.2em"
        @focus="open"
      />
      <van-field
        v-model="city"
        class="cont"
        is-link
        readonly
        label="常驻城市"
        placeholder="请选择城市"
        @click="show = true"
        label-width="4.2em"
      />
      <van-popup v-model="show" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title="请选择常驻城市"
          :options="options"
          :field-names="fieldNames"
          @close="show = false"
          @change="onChange"
          @finish="onFinish"
        />
      </van-popup>
      <van-field
        v-model="cartype"
        class="cont"
        is-link
        readonly
        label="购车方式"
        placeholder="请选择购车方式"
        @click="show1 = true"
        label-width="4.2em"
      />

      <van-popup v-model="show1" round position="bottom">
        <van-picker
          title=""
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
          @change="onChange"
        />
      </van-popup>
      <div class="ruls">
        <img
          class="agree"
          @click="change"
          v-show="agree"
          src="../assets/yes.png"
          alt=""
        />
        <img
          class="agree"
          @click="change"
          v-show="!agree"
          src="../assets/no.png"
          alt=""
        />
        <p class="p1">同意</p>
        <p class="p2" @click="goinfo">《个人信息使用授权书》</p>
      </div>
      <div class="btn" @click="submitCarBusinessMaterial">提交借款</div>
    </div>
    <div class="zw" style="font-size: 28px"></div>
    <div class="morebox">
      <img class="more" src="../assets/more.png" alt="" />
    </div>
    <div class="titbox">
      <img class="l" src="../assets/l.png" alt="" />
      <p class="tit">产品介绍</p>
      <img class="r" src="../assets/l.png" alt="" />
    </div>
    <div class="content">
      本产品由特邀合作机构提供，不是网贷，线上签约，客户经理电话回访。客户将自有车辆产权抵押，直接获取贷款，贷款资金可用于各种消费，抵押之后车辆仍归客户自行使用，贷款结清后解除抵押。
    </div>
    <div class="titbox">
      <img class="l" src="../assets/l.png" alt="" />
      <p class="tit">申请条件</p>
      <img class="r" src="../assets/l.png" alt="" />
    </div>
    <div class="content1 content">
      <div class="top-con">
        <img class="num" src="../assets/num.png" alt="" />
        <p class="top-p">年龄18-60周岁，本人名下车辆</p>
      </div>
      <div class="top-con top-con1">
        <img class="num" src="../assets/num.png" alt="" />
        <p class="top-p">
          名下有车且车辆未抵押，车龄小于10年，行驶里程小于15万公里
        </p>
      </div>
    </div>
    <div class="titbox">
      <img class="l" src="../assets/l.png" alt="" />
      <p class="tit">贷款流程</p>
      <img class="r" src="../assets/l.png" alt="" />
    </div>
    <div class="stpebox">
      <div class="stye-top">
        <p class="st-p">01</p>
        <p class="st-p">02</p>
        <p class="st-p">03</p>
        <p class="st-p">04</p>
      </div>
      <div class="stpe-bot">
        <p class="st-p1">在线申请</p>
        <p class="st-p1">专人确认</p>
        <p class="st-p1">确认签约</p>
        <p class="st-p1">钱款到账</p>
      </div>
      <div class="q-box">
        <div class="q-top">
          <img class="qimg" src="../assets/q.png" alt="" />
          <p class="qtit">审核需要准备哪些材料？</p>
        </div>
        <div class="q-btm">
          <div class="qb-l"></div>
          <div class="qb-r">
            一般为身份证、银行卡、行驶证、车辆登记证书，具体以工作人员联系为准。
          </div>
        </div>
      </div>
      <div class="q-box">
        <div class="q-top">
          <img class="qimg" src="../assets/q.png" alt="" />
          <p class="qtit">我能贷多少钱？多久可以到账？</p>
        </div>
        <div class="q-btm">
          <div class="qb-l"></div>
          <div class="qb-r">
            贷款额度一般为车辆评估价的7-10成，最高不超过100万元，资料齐全当天放款。
          </div>
        </div>
      </div>
      <div class="cooperate-box">
        <div class="cooperate-left">
          <span class="cooperate-span">合作机构</span>
          <span class="cooperate-span2">丽水佳恒融资性担保有限公司</span>
        </div>
        <p class="cooperate-p">
          <img class="cooperate-img" src="../assets/cooperate-icon.png" />
          <span class="cooperate-span">车闪贷</span>
        </p>
      </div>
    </div>
    <div class="fotter">
      <div class="ft-con">
        温馨提示：雪融花不会以任何名义向您收取费用，更不会要求您进行转账，提供银行密码、缴纳保证金等。请您注意识别，谨防伪冒诈骗行为。
      </div>
    </div>
    <!-- 输入车牌号 -->
    <van-popup v-model="wfcphshow" round position="bottom" class="wfcph">
      <Keyboards @sendCarNumber="getcrNumber"></Keyboards>
    </van-popup>
    <!-- 修改手机号 -->
    <van-overlay :show="showphone" @click="showphone = false">
      <div class="wrapper" @click.stop>
        <div class="blocks">
          <img
            class="clo-img"
            @click="closetc"
            src="../assets/close.png"
            alt=""
          />
          <p class="clotit">输入短信验证码</p>
          <div class="clo-box">
            <p class="clo-p1">验证码发送至：{{ this.phone }}</p>
            <p class="clo-p2">{{ remainingTime }}s</p>
          </div>
          <input
            class="clo-ipy"
            v-model="authCode"
            type="number"
            placeholder="请输入验证码"
          />
          <div class="sure" @click="identity">确认</div>
        </div>
      </div>
    </van-overlay>
    <!-- 挽留弹窗 -->
    <van-overlay :show="isback" @click="isback = false">
      <div class="wrapper1" @click.stop>
        <div class="backcont">
          <div class="backtit">确定放弃最快24小时放款嘛？</div>
          <div class="back-con">
            <img class="back-img" src="../assets/icon.png" alt="" />
            <div class="back-r">
              <p class="back-p1">钱拿走车照开</p>
              <p class="back-p2">最快当天到账，不押车</p>
            </div>
          </div>
          <div class="back-con">
            <img class="back-img" src="../assets/icon1.png" alt="" />
            <div class="back-r">
              <p class="back-p1">灵活借还</p>
              <p class="back-p2">最高可贷100万，最长48期还款</p>
            </div>
          </div>
          <div class="back-con">
            <img class="back-img" src="../assets/icon2.png" alt="" />
            <div class="back-r">
              <p class="back-p1">全流程服务</p>
              <p class="back-p2">专业定制个性化融资方案</p>
            </div>
          </div>
          <div class="back-sure" @click="nobanck">继续申请</div>
          <div class="back-no" @click="sureback">残忍拒绝</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Toast } from "vant";
// 引入
import Keyboards from "../components/keybords.vue";
import axios from "axios";
// 注册使用

export default {
  name: "Home",
  components: {
    Keyboards,
  },
  data() {
    return {
      name: "",
      phone: "",
      oldphone: "",
      phoneisUpdate: true,
      carnum: "",
      city: "",
      cityName: "",
      cityId: "",
      cartype: "全款购车",
      buyCarType: "",
      cascaderValue: "",
      nameDisabled: false,
      show: false,
      show1: false,
      agree: false,
      wfcphshow: false,
      showphone: false,
      authCode: "",
      identitytoken: "",
      isback: false,
      isupdatePhone: false,
      first: "",
      numArr: [],
      remainingTime: 60, // 初始倒计时60秒
      timer: null, // 计时器
      token: "",
      fieldNames: {
        text: "districtName",
        value: "districtId",
        children: "child",
      },
      columns: ["全款购车", "车贷已结清", "车贷未结清"],
      options: [],
      //devurl:"http://121.199.11.4:18085/",
      devurl:' https://api.snowstormplus.com/'
    };
  },
  mounted() {
    this.token = this.$route.query.token;
    axios
      .get(this.devurl+"sso/info", {
        headers: {
          Authorization: this.token,
        },
      })
      .then((res) => {
        // console.log(res.data)
        this.phone = res.data.data.phone;
        // this.phone = '15637915085'
        this.oldphone = res.data.data.phone;
        var reg = /(\d{3})\d{4}(\d{4})/;
        this.phone = this.phone.replace(reg, "$1****$2");
        this.name = res.data.data.realName;
        if (this.name) {
          this.nameDisabled = true;
        } else {
          this.nameDisabled = false;
        }
        // }
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(this.devurl+"home/h5GetCity", {
        headers: {
          Authorization: this.token,
        },
      })
      .then((response) => {
        //console.log(response.data.data);
        this.options = response.data.data;
        this.city = this.$route.query.city;
        if(this.city!=''){
          this.options.map((item) => {
          item.child.map((it) => {
            if (this.city.includes(it.districtName)) {
              console.log(it)
              this.cityId = it.districtId;
              this.cityName = it.districtName;
              this.city=it.districtName
            }
          });
        });
        }
       
      })
      .catch((error) => {
        console.error(error);
      });
    window.appReturn = this.appReturn;
  },
  beforeDestroy() {
    this.clearCountdown();
  },
  methods: {
    //城市
    onFinish({ selectedOptions }) {
      this.show = false;
      this.city = selectedOptions[1].districtName;
      this.cityId = this.cascaderValue;
      if (selectedOptions[1].districtName) {
        this.cityName = selectedOptions[1].districtName;
      } else {
        this.cityName = "";
      }
      flutterMethod.postMessage("city" + this.city);
    },
    //信息授权书
    goinfo() {
      window.location.href = "https://admin.snowstormplus.com/doc/7.html";
    },
    //倒计时
    startCountdown() {
      this.timer = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--;
        } else {
          this.clearCountdown();
        }
      }, 1000);
    },
    clearCountdown() {
      clearInterval(this.timer);
      this.timer = null;
    },
    //挽留弹出
    sureback() {
      flutterMethod.postMessage("back");
      this.isback = false;
      //返回到app 的固定页面
    },
    nobanck() {
      this.isback = false;
    },
    appReturn() {
      this.isback = true;
    },
    //修改手机号
    showphonezw() {
      const regex = /^1[3-9]\d{9}$/; // 中国大陆手机号码正则，以1开头，第二位是3-9，接下来是9位数字
      if (this.isupdatePhone == true) {
        //验证新手机号
        if (regex.test(this.phone)) {
          //请求发送验证码接口
          axios
            .get(
              this.devurl+"sso/getAuthCode",
              {
                params: {
                  authCodeFlag: "carApply",
                  telephone: this.phone,
                },
              },
              {
                headers: {
                  Authorization: this.token,
                },
              }
            )
            .then((response) => {
              // console.log(response.data);
              if (response.data.code == 200) {
                Toast("验证码已发送");
                this.showphone = true;
                this.startCountdown();
              }
              // this.options= response.data.data
            })
            .catch((error) => {
              // Toast('网络请求失败');
            });
        } else {
          Toast("手机号格式不正确");
        }
      } else {
        //验证旧手机号
        if (regex.test(this.oldphone)) {
          //请求发送验证码接口
          axios
            .get(
              this.devurl+"sso/getAuthCode",
              {
                params: {
                  authCodeFlag: "carApply",
                  telephone: this.oldphone,
                },
              },
              {
                headers: {
                  Authorization: this.token,
                },
              }
            )
            .then((response) => {
              // console.log(response.data);
              if (response.data.code == 200) {
                Toast("验证码已发送");
                this.showphone = true;
                this.startCountdown();
              }
              // this.options= response.data.data
            })
            .catch((error) => {
              // Toast('网络请求失败');
            });
        } else {
          Toast("手机号格式不正确");
        }
      }
    },
    identity() {
      if (this.isupdatePhone == true) {
        //验证验证码 并且提交
        axios
          .post(
            this.devurl+"my/identity",
            {
              authCodeFlag: "carApply",
              phone: this.phone,
              authCode: this.authCode,
            },
            {
              headers: {
                Authorization: this.token,
              },
            }
          )
          .then((response) => {
            // console.log(response.data);
            if (response.data.code == 200) {
              //console.log(response.data)
              this.identitytoken = response.data.data;
              if (this.cartype == "全款购车") {
                this.buyCarType = "full";
              }
              if (this.cartype == "车贷已结清") {
                this.buyCarType = "settled";
              }
              if (this.cartype == "车贷未结清") {
                this.buyCarType = "unsettled";
              }
              if (this.name == "" || this.name === undefined) {
                Toast("请输入姓名");
                return;
              }
              if (this.carnum == "") {
                Toast("请输入车牌");
                return;
              }
              if (this.cityId == "") {
                Toast("请选择城市");
                return;
              }
              if (this.buyCarType == "") {
                Toast("请选择购车方式");
                return;
              }
              if (this.agree == false) {
                Toast("请点击同意授权书");
                return;
              }
              axios
                .post(
                  this.devurl+"home/submitCarBusinessMaterial",
                  {
                    phone: this.phone,
                    token: this.identitytoken,
                    realName: this.name,
                    city: this.cityName,
                    cityId: this.cityId,
                    carLicense: this.carnum,
                    buyCarType: this.buyCarType,
                  },
                  {
                    headers: {
                      Authorization: this.token,
                    },
                  }
                )
                .then((response) => {
                  // console.log(response.data);
                  if (response.data.code == 200) {
                    Toast("提交成功");
                    flutterMethod.postMessage("orderType_carApply");
                    //console.log(response.data)
                  }
                  // this.options= response.data.data
                })
                .catch((error) => {
                  console.error(error);
                });
            }
            // this.options= response.data.data
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        axios
          .post(
            this.devurl+"my/identity",
            {
              authCodeFlag: "carApply",
              phone: this.oldphone,
              authCode: this.authCode,
            },
            {
              headers: {
                Authorization: this.token,
              },
            }
          )
          .then((response) => {
            // console.log(response.data);
            if (response.data.code == 200) {
              //console.log(response.data)
              // this.identitytoken = response.data.data;
              this.phone = "";
              this.isupdatePhone = true;
              this.phoneisUpdate = false;
              this.showphone = false;
              this.remainingTime = 60;
              this.authCode = "";
              this.clearCountdown();
            }
            // this.options= response.data.data
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    //提交车抵贷
    submitCarBusinessMaterial() {
      if (this.isupdatePhone == true) {
        this.showphone = true;
        this.startCountdown();
        this.showphonezw();
      } else {
        //提交数据
        if (this.cartype == "全款购车") {
          this.buyCarType = "full";
        }
        if (this.cartype == "车贷已结清") {
          this.buyCarType = "settled";
        }
        if (this.cartype == "车贷未结清") {
          this.buyCarType = "unsettled";
        }
        if (this.name == "" || this.name === undefined) {
          Toast("请输入姓名");
          return;
        }
        if (this.carnum == "") {
          Toast("请输入车牌");
          return;
        }
        if (this.cityId == "") {
          Toast("请选择城市");
          return;
        }
        if (this.buyCarType == "") {
          Toast("请选择购车方式");
          return;
        }
        if (this.agree == false) {
          Toast("请点击同意授权书");
          return;
        }
        axios
          .post(
            this.devurl+"home/submitCarBusinessMaterial",
            {
              phone: this.oldphone,
              realName: this.name,
              city: this.cityName,
              cityId: this.cityId,
              carLicense: this.carnum,
              buyCarType: this.buyCarType,
            },
            {
              headers: {
                Authorization: this.token,
              },
            }
          )
          .then((response) => {
            // console.log(response.data);
            if (response.data.code == 200) {
              Toast("提交成功");
              flutterMethod.postMessage("orderType_carApply");
              //console.log(response.data)
            }
            // this.options= response.data.data
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },

    closetc() {
      this.showphone = false;
    },
    //同意规则
    change() {
      this.agree = !this.agree;
    },
    //购车方式
    onConfirm(value, index) {
      this.show1 = false;
      // Toast(`当前值：${value}, 当前索引：${index}`);
      this.cartype = value;
    },
    onChange(picker, value, index) {
      // Toast(`当前值：${value}, 当前索引：${index}`);
    },
    onCancel() {
      this.show1 = false;
      // Toast('取消');
    },
    //车牌弹窗
    open() {
      document.activeElement.blur();
      this.wfcphshow = true;
    },
    getcrNumber(val) {
      const carNumberReg =
        /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]$/;
      if (carNumberReg.test(val)) {
        this.wfcphshow = false;
        this.carnum = val;
        //console.log(val, "车牌号");
      }
    },
  },
};
</script>
<style lang="less" scoped>

.cooperate-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 50px;
  box-sizing: border-box;
  .cooperate-img {
    height: 35px;
    width: 35px;
    margin-right: 10px;
  }
  .cooperate-p {
    display: flex;
    align-items: center;
  }
  .cooperate-span {
    font-size: 18px;
    color: #010B3C;
    width: 101px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
  }
  .cooperate-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 0;
  }
  .cooperate-span2 {
    font-size: 18px;
    color: #010B3C;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
  }
}

.van-cell > .van-field__label {
  width: 4.2rem !important;
}
.home {
  background: #f2f3f6;
  position: relative;
}
.wfcph {
  height: 800px;
}
.form-content {
  width: 670px;
  height: 966px;
  background: #ffffff;
  border-radius: 16px;
  margin: 0 40px;
  position: absolute;
  top: 335px;
}
.zw {
  width: 750px;
  height: 638px;
}
.cont {
  width: 598px;
  height: 97px;
  margin-top: 44px;
  margin-left: 36px;
  background: #f1f2f8;
  border-radius: 49px;
  padding-top: 28px;
}
.ruls {
  margin-left: 42px;
  margin-right: 22px;
  overflow: hidden;
  margin-top: 43px;
  height: 32px;
  line-height: 32px;
}
.agree {
  width: 32px;
  height: 32px;
  float: left;
  margin-right: 5px;
}
.p1 {
  float: left;
  font-size: 26px;
  color: #383c60;
}
.p2 {
  float: left;
  font-size: 26px;
  color: #0a5bf4;
}
.btn {
  text-align: center;
  width: 598px;
  height: 100px;
  background: #0d76ff;
  border-radius: 55px;
  font-weight: 600;
  font-size: 30px;
  color: #ffffff;
  line-height: 100px;
  margin-top: 49px;
  margin-left: 39px;
}
.morebox {
  width: 100%;
  margin-top: 46px;
  .more {
    display: block;
    width: 52px;
    height: 42px;
    margin: 0 auto;
  }
}
.titbox {
  overflow: hidden;
  height: 48px;
  line-height: 48px;
  margin-top: 32px;
  .l {
    width: 68px;
    height: 26px;
    float: left;
    margin-top: 11px;
    margin-left: 218px;
  }
  .tit {
    font-weight: 600;
    font-size: 34px;
    color: #010b3c;
    float: left;
    margin: 0 20px;
  }
  .r {
    width: 68px;
    height: 26px;
    float: left;
    margin-top: 11px;
  }
}
.content {
  width: 598px;
  height: 151px;
  background: #ffffff;
  border-radius: 16px;
  font-weight: 400;
  font-size: 26px;
  color: #010b3c;
  padding: 28px 36px;
  margin: 22px 40px 0;
}
.content1 {
  height: 187px;
}
.top-con {
  overflow: hidden;
  margin-bottom: 13px;
  margin-top: 30px;
  .num {
    display: block;
    float: left;
    width: 24px;
    height: 24px;
    margin-top: 6px;
    margin-right: 6px;
  }
  .top-p {
    width: 566px;
    font-weight: 400;
    font-size: 26px;
    color: #010b3c;
    float: right;
  }
}
.top-con1 {
  margin-top: 13px;
}
.stpebox {
  width: 668px;
  margin: 0 auto;
  margin-top: 32px;
}
.stye-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.st-p {
  width: 25%;
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  color: #010b3c;
}
.stpe-bot {
  display: flex;
  justify-content: space-between;
}
.st-p1 {
  width: 25%;
  text-align: center;
  font-weight: 400;
  font-size: 26px;
  color: #010b3c;
}
.q-box {
  width: 646px;
  margin-left: 40px;
  margin-top: 75px;
  .q-top {
    overflow: hidden;
    margin-bottom: 17px;
    .qimg {
      width: 28px;
      height: 28px;
      float: left;
      margin-right: 16px;
    }
    .qtit {
      font-weight: 600;
      font-size: 26px;
      color: #010b3c;
    }
  }
  .q-btm {
    overflow: hidden;
    .qb-l {
      width: 2px;
      height: 64px;
      border: 2px solid #979797;
      opacity: 0.19;
      float: left;
      margin-right: 30px;
    }
    .qb-r {
      width: 602px;
      font-weight: 400;
      font-size: 22px;
      color: #7e8394;
      float: left;
    }
  }
}
.fotter {
  width: 750px;
  height: 240px;
  background: #ededed;
  margin-top: 130px;
  .ft-con {
    padding: 48px 40px;
    font-weight: 400;
    font-size: 20px;
    color: #9f9eaf;
  }
}
.wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}
.blocks {
  width: 750px;
  height: 1000px;
  background-color: #fff;
  border-radius: 16px 16px 0px 0px;
  .clo-img {
    width: 28px;
    height: 28px;
    display: block;
    margin-top: 42px;
    margin-left: 45px;
  }
  .clotit {
    font-weight: 600;
    font-size: 50px;
    color: #010b3c;
    margin-top: 42px;
    margin-left: 42px;
  }
  .clo-box {
    margin: 5px 58px 0 42px;
    overflow: hidden;
    .clo-p1 {
      font-weight: 400;
      font-size: 30px;
      color: #9f9eaf;
      float: left;
    }
    .clo-p2 {
      font-weight: 400;
      font-size: 30px;
      color: #9f9eaf;
      float: right;
    }
  }
  .clo-ipy {
    width: 600px;
    height: 104px;
    margin: 40px 58px 40px 0px;
    border: none;
    border-radius: 55px;
    background: #f1f2f8;
    font-size: 30px;
    padding-left: 50px;
    margin-left: 42px;
  }
  .sure {
    width: 650px;
    height: 104px;
    background: #0d76ff;
    border-radius: 55px;
    margin: 20px 58px 20px 42px;
    font-weight: 600;
    font-size: 30px;
    color: #ffffff;
    line-height: 104px;
    text-align: center;
  }
}
.wrapper1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.backcont {
  width: 572px;
  height: 834px;
  background: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  .backtit {
    width: 434px;
    margin: 54px 90px 44px 48px;
    font-weight: 600;
    font-size: 38px;
    color: #000000;
    float: left;
  }
  .back-con {
    margin-left: 48px;
    margin-bottom: 41px;
    float: left;
    overflow: hidden;
    .back-img {
      width: 54px;
      height: 48px;
      float: left;
      margin: 22px 20px 0 0;
    }
    .back-r {
      float: left;
      overflow: hidden;
      .back-p1 {
        font-weight: 600;
        font-size: 34px;
        color: #010b3c;
      }
      .back-p2 {
        font-weight: 400;
        font-size: 26px;
        color: #7e8394;
      }
    }
  }
  .back-sure {
    width: 490px;
    height: 110px;
    background: #0d76ff;
    border-radius: 16px;
    font-weight: 600;
    font-size: 30px;
    color: #ffffff;
    text-align: center;
    line-height: 110px;
    margin-left: 40px;
    float: left;
  }
  .back-no {
    width: 100%;
    float: left;
    text-align: center;
    font-weight: 400;
    font-size: 26px;
    color: #7c7c7c;
    margin-top: 36px;
  }
}
</style>
